<template>
    <div>
        <div class="top" v-if="addressToDisplay">
            <div v-if="address.attentionOf !== ''">
                <strong>{{ address.attentionOf }}</strong>
            </div>
            <div v-if="address.phone !== ''">
                <a class="phone-number" :href="'tel:' + address.phone">{{ address.phone }}</a>
            </div>
            <div v-if="address.email !== ''">
                {{ address.email }}
            </div>
        </div>
        <div class="actions" v-if="! isNotified">
            <div v-if="informing !== false">
                <div class="loading-spinner">
                    <div data-uk-spinner="ratio: 4" />
                </div>
                <div class="loading-spinner" />
                <div v-if="informing === 'SMS'">
                    {{ $t('informing_by_sms') }}
                </div>
                <div v-else-if="informing === 'email'">
                    {{ $t('informing_by_email') }}
                </div>
                <div v-else-if="informing === 'email_and_SMS'">
                    {{ $t('informing_by_email_and_sms') }}
                </div>
                <div v-else>
                    {{ $t('informing_by_phone') }}
                </div>
            </div>
            <div v-else>
                <div>
                    <theme-button
                        icon="mobile"
                        :description="$t('send_sms')"
                        :disabled="address.phone === ''"
                        @click="sendNotification('SMS')"
                    />
                </div>
                <div>
                    <theme-button
                        icon="envelope"
                        :description="$t('send_email')"
                        :disabled="address.email === ''"
                        @click="sendNotification('email')"
                    />
                </div>
                <div>
                    <theme-button
                        icon="signal-stream"
                        :description="$t('send_email_and_sms')"
                        :disabled="address.email === ''"
                        @click="sendNotification('email_and_SMS')"
                    />
                </div>
                <div>
                    <theme-button
                        icon="phone"
                        :description="$t('informed_by_phone')"
                        :disabled="address.phone === ''"
                        @click="sendNotification('phone')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .top {
        border:        1px solid #FFFFFF;
        border-radius: 5px;
        margin:        0 auto 50px;
        width:         50%;
        padding:       30px;
        text-align:    center;
        color:         #FFFFFF;

        a {
            color: #FFFFFF;
        }
    }
    .actions {
        @include center();
        margin: 40px 0 40px -35px;
    }
</style>

<script>
import { apiClient } from '@/api';
import { ITEM_TYPE } from "@/constants";
import ThemeButton   from '../../../../../../../components/button/ThemeButton';

export default {
    name: "InformCustomer",
    components: { ThemeButton },
    props: {
        isNotified: { type: Boolean, required: true },
        item:       { type: Object,  required: true },
    },
    data()
    {
        return {
            informing:      false,
            loadingMessage: null,
        }
    },
    computed: {
        address() {
            if (this.purchaseReceipt) return this.purchaseReceipt.pickUpAddress;
            else                      return this.packingSlip.deliveryAddress;
        },
        addressToDisplay() {
            return this.address.attentionOf !== '' || this.address.phone !== '' || this.address.email !== '';
        },
        packingSlip() {
            if (! this.item.type || this.item.type === ITEM_TYPE.PACKING_SLIP) return this.item.packingSlip;
            else                                                               return null;
        },
        purchaseReceipt() {
            if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) return this.item.purchaseReceipt;
            else                                                                 return null;
        }
    },
    methods: {
        async sendNotification(method) {
            try {
                this.informing = method;

                let response = null;
                if (this.purchaseReceipt) {
                    response = await apiClient.post(`transport/purchase_receipts/${this.purchaseReceipt.id}/notify`, { method });
                } else {
                    response = await apiClient.post(`transport/packing_slips/${this.packingSlip.id}/notify`, { method });
                }
                this.$emit('informed', response.data.data);
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.informing = false;
            }
        },
    }
};

</script>
