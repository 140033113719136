<template>
    <div class="centered" data-uk-grid>
        <div
            v-for="attachment in packingSlip.deliveryLocationAttachments"
            :key="attachment.id"
            :class="widthClass"
        >
            <div>
                <thumbnail-file-image :file="attachment" />
            </div>
        </div>
    </div>
</template>

<style scoped>
    .centered {
        position:        relative;
        display:         flex;
        justify-content: center;
        margin:          50px 0;
    }
</style>

<script>
import ThumbnailFileImage from '@/views/transportRide/overview/ride/item/options/overlay/ThumbnailFileImage';
export default {
    name: "DeliveryLocation",
    components: { ThumbnailFileImage },

    props: {
        packingSlip: { type: Object, required: true },
    },

    computed: {
        widthClass() {
            return this.packingSlip.attachments.length === 4
                ? 'uk-width-1-2'
                : 'uk-width-1-3'
            ;
        }
    },
};
</script>
