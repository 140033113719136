<template>
    <div>
        <button
            class="close"
            type="button"
            @click="$emit('close')"
        >
            <i class="fal fa-times" />
        </button>
        <div class="spinner image-overlay" v-if="loading">
            <div data-uk-spinner="ratio:4" />
            <div>
                {{ $t('loading.image') }}
            </div>
        </div>
        <img
            v-else-if="url"
            class="image-overlay"
            :src="url"
            :alt="file.name"
        />
        <div v-else class="no-image">
            <i class="fas fa-exclamation-circle" />
            <p>{{ $t('error.loading') }}</p>
        </div>
    </div>
</template>

<style scoped lang="scss">

.image-overlay {
    margin: auto;
    min-height:    auto;
    display:       block;
    border-radius: 3px;
    position:      absolute;
    top:           -200px;
    left:          15px;
    bottom:        0;
    right:         0;
    box-shadow:    var(--image-overlay);
    transition:    all 0.3s 0s ease;
    z-index:       10;
}

.spinner {
    width:           250px;
    height:          250px;
    object-fit:      contain;
    border:          1px solid #9d9d9d;
    border-radius:   3px;
    justify-content: center;
    background:      var(--theme-button-icon);;
    display:         flex;
    flex-direction:  column;
    gap:             40px;
    align-items:     center;
    color:           #ffffff;
}

.no-image {
    width:         250px;
    height:        250px;
    margin:        auto;
    border:        1px solid #9d9d9d;
    border-radius: 3px;
    text-align:    center;

    i {
        margin-top: 12px;
        color:      #FFFFFF;
        font-size:  75px;
    }

    p {
        color:      #FFFFFF;
        margin-top: 10px;
        font-size:  14px;
    }
}


.close {
    position:   fixed;
    top:        20px;
    right:      18px;
    background: #fff;
    width:      75px;
    height:     77px;
    z-index:    11;
    color:      var(--item-blue-to-darkBlue) !important;
}

i {
    font-size: 40px;
}

</style>

<script>
import { apiClient } from '@/api';

export default {
    name: "FullWidthFileImage",
    components: { },
    props: {
        file: { type: Object, required: true },
    },
    data() {
        return {
            url:     null,
            loading: true,
        };
    },

    async mounted() {
        try {
            const response = await apiClient.get(this.file.url.replace('/v3/api', ''), { responseType: 'blob' });
            this.url       = URL.createObjectURL(response.data);
        } catch (e) {
            console.error(e); //eslint-disable-line no-console
            throw e;
        } finally {
            if (! this.file.mimeType.startsWith('image/')) {
                window.open(this.url);
                this.$emit('close');
            }
            this.loading = false;
        }
    },
};
</script>
