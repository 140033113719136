<template>
    <div>
        <div v-if="errorMessage !== null">
            <div class="center message">{{ errorMessage }}</div>
            <div
                v-if="errorMessage === $t('error.loading_payment_methods')"
                class="center close-button"
                @click="loadPaymentMethods(); errorMessage = null;"
            >
                {{ $t('try_again') }}
            </div>
            <div
                v-if="errorMessage === $t('error.sending_invoice')"
                class="center close-button"
                @click="emailInvoice(invoiceToMail)"
            >
                {{ $t('try_again') }}
            </div>
            <div
                v-else
                class="center close-button"
                @click="loadInvoices(); errorMessage = null;"
            >
                {{ $t('close') }}
            </div>
        </div>
        <div v-else-if="loadingMessage !== null">
            <div class="center spinner" data-uk-spinner="ratio: 4" />
            <div class="center message">{{ loadingMessage }}</div>
        </div>
        <div v-else-if="confirmMessage">
            <div class="center message">{{ confirmMessage }}</div>
            <div
                class="center close-button"
                @click="confirmMessage = null;"
            >
                {{ $t('ok') }}
            </div>
        </div>
        <table v-else-if="invoiceToPay !== null">
            <tr>
                <th colspan="3" class="header-text">{{ $t('payment_invoice_of', { invoice: invoiceToPay.number, date: convertDateToLocale(invoiceToPay.issuedAt) }) }}</th>
            </tr>
            <tr>
                <td colspan="3">
                    <div class="payment-condition">{{ getPaymentCondition(invoiceToPay) }}</div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="display: flex; justify-content: space-between">
                        <span v-html="$t('to_pay', { amount: $options.filters.amount(invoiceToPay.outstandingAmount) })" />
                        <span>&euro;</span>
                    </div>
                </td>
                <td style="width: calc((92vw / 3) - 10px); text-align: right; padding-right: 8px;">
                    <div style="padding-right: 12px;">{{ invoiceToPay.outstandingAmount | amount}}</div>
                </td>
                <td style="width: calc((92vw / 3) - 10px)"/>
            </tr>
            <tr>
                <td>
                    <div style="display: flex; justify-content: space-between">
                        <span v-html="$t('paid_now')" />
                        <span>&euro;</span>
                    </div>
                </td>
                <td colspan="2">
                    <input
                        type="text"
                        inputmode="decimal"
                        pattern="\d*([\.,]\d*)?"
                        class="amount-input"
                        v-model="paymentToAdd.amount"
                    />
                </td>
            </tr>
            <tr>
                <td colspan="3" style="padding-top: 25px">
                    <div class="payment-method-button-container">
                        <div
                            v-for="method in paymentMethods"
                            :key="method.id"
                            class="payment-method-button"
                            :class="method.id === paymentToAdd.method ? 'selected' : null"
                            @click="paymentToAdd.method = method.id"
                        >
                            {{ method.name }}
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="3">
                    <div
                        :disabled="disableAddPayment"
                        @click="addPayment"
                        class="add-payment-button"
                    >
                        {{ $t('add_payment') }}
                    </div>
                </td>
            </tr>
        </table>
        <table
            v-else
            v-for="invoice in invoices"
            :key="invoice.id"
        >
            <tr :key="invoice.id + 'A'">
                <th colspan="3" class="header-text">{{ $t('payment_invoice_of', { invoice: invoice.number, date: convertDateToLocale(invoice.issuedAt) }) }}</th>
            </tr>
            <tr :key="invoice.id + 'B'">
                <td colspan="3">
                    <div class="payment-condition">{{ getPaymentCondition(invoice) }}</div>
                </td>
            </tr>
            <tr :key="invoice.id + 'C'">
                <td style="width: calc(100% / 3)">
                    <div style="display: flex; justify-content: space-between">
                        <span v-html="$t('total')" />
                        <span>&euro;</span>
                    </div>
                </td>
                <td style="width: calc(100% / 3); text-align: right;">{{ $options.filters.amount(invoice.chargedAmount) }}</td>
                <td />
            </tr>
            <tr :key="invoice.id + 'D'">
                <td>
                    <div style="display: flex; justify-content: space-between">
                        <span v-html="$t('paid')" />
                        <span>&euro;</span>
                    </div>
                </td>
                <td style="text-align: right;">{{ $options.filters.amount(invoice.paidAmount) }}</td>
                <td></td>
            </tr>
            <tr :key="invoice.id + 'E'">
                <td>
                    <div style="display: flex; justify-content: space-between">
                        <span v-html="$t('outstanding')" />
                        <span>&euro;</span>
                    </div>
                </td>
                <td style="text-align: right">{{ $options.filters.amount(invoice.outstandingAmount) }}</td>
                <td></td>
            </tr>
            <tr v-if="somethingLeftToPay(invoice)" :key="invoice.id + 'F'">
                <td colspan="3">
                    <div @click="startPayment(invoice)" class="add-payment-button">
                        {{ $t('add_payment_for', { invoice: invoice.number }) }}
                    </div>
                </td>
            </tr>
            <template v-if="executeFromVersion(21, 96)">
                <tr>
                    <td colspan="3">
                        <div
                            v-if="invoice.isMailableFromDriverApp"
                            class="email-invoice-button"
                            @click="emailInvoice(invoice)"
                        >
                            {{ $t('email_invoice', { invoice: invoice.id }) }}
                        </div>
                        <div v-else class="email-invoice-button disabled">
                            {{ $t('invoice_cant_be_emailed', { invoice: invoice.id }) }}
                        </div>
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<style lang="scss" scoped>
    table {
        text-align: left;
        width:      100%;
    }
    .header-text {
        font-size: 25px;
    }
    .message {
        margin-top:    5vh !important;
        margin-bottom: 5vh !important;
    }
    .spinner {
        margin-top: 5vh !important;
    }
    .payment-condition {
        margin-bottom: 10px;
    }
    .amount-input {
        text-align: right;
        padding:    0 10px;
        background: #fff;
        color:      #666;
        border:     1px solid #e5e5e5;
        box-sizing: border-box;
        font-size:  18px;
        width:      calc((92vw / 3) - 12px);
        height:     35px;
    }
    .payment-method-button-container {
        display:         flex;
        flex-flow:       row wrap;
        gap:             2vw;
        justify-content: space-around;
    }
    .payment-method-button {
        width:           calc((92vw / 3) - 16px);
        height:          calc((92vw / 3) - 16px);
        align-items:     center;
        background:      rgba(0,0,0,0.5);
        display:         flex;
        justify-content: center;
        text-align:      center;
        overflow-wrap:   anywhere;
        border-radius:   4px;

        &.selected {
            border:     2px solid #FFFFFF;
            box-sizing: border-box;
        }
    }
    .add-payment-button,
    .email-invoice-button {
        margin-top:    2vw;
        text-align:    center;
        background:    rgba(0, 0, 0, 0.5);
        padding:       15px;
        margin-bottom: 20px;
        border-radius: 4px;

        &.disabled {
            background: rgba(0, 0, 0, 0.15);
        }
    }
    .close-button {
        @extend .add-payment-button;

        margin-bottom: 5vh !important;
    }
    .center {
        @include center();
    }
</style>

<script>

import { apiClient }                 from '@/api';
import { convertToLocaleDateString } from '@/support/dateFunctions';
import { executeFromVersion }        from "@/support/codeVersion";
import { INVOICE_PAYMENT_INCLUDES }  from "@/constants";
import numberParser                  from 'multi-number-parse';

const CONNECTOR_SOFTPOS = 4; // should be the same in Retail

export default {
    name: "Invoices",

    props: {
        invoices: { type: Array,  required: true },
        item:     { type: Object, required: true },
        order:    { type: Number, required: true }
    },
    data() {
        return {
            confirmMessage: null,
            errorMessage:   null,
            invoiceToMail:  null,
            invoiceToPay:   null,
            loadingMessage: null,
            paymentToAdd:   null,
            paymentMethods: [],
            pinPayment:     null,
        }
    },
    async mounted()
    {
        await this.loadPaymentMethods();
    },
    computed: {
        disableAddPayment() {
            const amount = numberParser(this.paymentToAdd.amount);
            return isNaN(amount) || amount === 0 || this.paymentToAdd.method === null;
        }
    },
    methods: {
        executeFromVersion(major, minor) {
            return executeFromVersion(major, minor);
        },
        async loadPaymentMethods() {
            try {
                this.loadingMessage = this.$t('loading.payment_methods')

                let result = null;
                if (executeFromVersion(21, 94)) {
                    result = await apiClient.get('payment_methods/driver_app');
                } else {
                    result = await apiClient.get('invoices/payment_methods');
                }
                this.paymentMethods = result.data.data;
            } catch (e) {
                this.errorMessage   = this.$t('error.loading_payment_methods');
            } finally {
                this.loadingMessage = null;
            }
        },
        async loadInvoices() {
            try {
                this.loadingMessage = this.$t('loading.invoices');
                const result        = await apiClient.get('invoices', { params: { orderId: this.order }});
                
                this.$emit('updated-invoices', result.data.data);
            } catch (e) {
                this.errorMessage = this.$t('error.loading_invoices');
            } finally {
                this.loadingMessage = null;
            }
        },
        startPayment(invoice) {
            this.paymentToAdd = {
                amount: this.$options.filters.amount(invoice.outstandingAmount),
                method: null,
            }
            this.invoiceToPay = invoice;
        },
        async addPayment() {
            if (this.disableAddPayment) return;

            try {
                this.loadingMessage = this.$t('adding_payment')

                const payment = this.paymentMethods.find(method => method.id === this.paymentToAdd.method);

                if (payment.connector === CONNECTOR_SOFTPOS) {
                    await this.handlePinPayment();
                }

                const paymentToAdd = {
                    amount: numberParser(this.paymentToAdd.amount),
                    method: this.paymentToAdd.method,
                    pinId:  this.pinPayment ? this.pinPayment.id : null,
                };
                const config  = {
                    params: {
                        include: INVOICE_PAYMENT_INCLUDES,
                    }
                };
                const response = await apiClient.post(
                    `invoices/${this.invoiceToPay.id}/payments`,
                    paymentToAdd,
                    config
                );
                let invoices         = this.invoices;
                const invoiceIdx     = invoices.findIndex(invoice => invoice.id === this.invoiceToPay.id);
                invoices[invoiceIdx] = response.data.data.invoice;
                
                this.$emit('updated-invoices', invoices);

            } catch (e) {
                this.errorMessage = this.$t('error.adding_payment');
            } finally {
                this.invoiceToPay   = null;
                this.paymentToAdd   = null;
                this.loadingMessage = null;
                this.pinPayment     = null;
            }
        },
        async emailInvoice(invoice) {
            try {
                this.loadingMessage = this.$t('busy_with.emailing_invoice', { invoice: invoice.id });
                this.errorMessage   = null;
                this.invoiceToMail  = invoice;

                await apiClient.post(`transport/items/${this.item.id}/invoices/${invoice.id}/email`);

                this.confirmMessage = this.$t('invoice_send_success', { invoice: invoice.id });
                this.invoiceToMail  = null;
            } catch (e) {
                this.errorMessage   = this.$t('error.sending_invoice');
            } finally {
                this.loadingMessage = null;
            }
        },
        getPaymentCondition(invoice) {
            if (invoice.paymentCondition instanceof Object) return invoice.paymentCondition.description;

            return '-';
        },
        somethingLeftToPay(invoice) {
            return Number(invoice.outstandingAmount) !== 0;
        },
        convertDateToLocale(date) {
            return convertToLocaleDateString(date);
        },
        async handlePinPayment() {
            const data = {
                amount:        numberParser(this.paymentToAdd.amount),
                paymentMethod: this.paymentToAdd.method,
                salesOrder:    this.order,
            };

            const response  = await apiClient.post('pin/payments', data);
            this.pinPayment = response.data.data;

            window.open(`pay://localhost/transactions?transactionId=${this.pinPayment.id}`)

            await this.startPinPolling();
        },
        async startPinPolling() {
            while (this.pinPayment.status === 0) {
                await new Promise(resolve => { setTimeout(resolve, 1000); }); // poll every second
                await this.updatePinPayment();
            }

            if (this.pinPayment.status !== 99) {
                throw new Error('PIN transaction has failed.');
            }
        },
        async updatePinPayment() {
            if (! this.pinPayment) return;

            const response  = await apiClient.get(`pin/payments/${this.pinPayment.id}`);
            this.pinPayment = response.data.data;
        }
    }
};
</script>
