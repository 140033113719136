<template>
    <div>
        <div class="upload">
            <input
                type="file"
                accept="image/*"
                capture="camera"
                multiple
                :disabled="uploading"
                @change="addPictures"
            />
            <theme-button icon="camera" :description="$t('add')" />
        </div>
        <div class="centered spinner" v-if="uploading">
            <div data-uk-spinner="ratio:4" />
            <div>
                {{ $t('uploading_pic') }}
            </div>
        </div>
        <div v-else class="centered" data-uk-grid>
            <div
                v-for="attachment in attachments"
                :key="`attachment_${attachment.id}`"
                :class="widthClass"
            >
                <div>
                    <thumbnail-file-image :file="attachment" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .upload {
        position: relative;

        input {
            top:      10px;
            position: absolute;
            z-index:  1000;
            width:    290px;
            height:   60px;
            opacity:  0;
        }
    }
    .centered {
        position:        relative;
        display:         flex;
        justify-content: center;
        margin:          50px 0;
    }
    .spinner {
        width:           250px;
        height:          250px;
        object-fit:      contain;
        border:          1px solid #9d9d9d;
        border-radius:   3px;
        justify-content: center;
        background:      var(--theme-button-icon);;
        display:         flex;
        flex-direction:  column;
        gap:             40px;
        align-items:     center;
        color:           #ffffff;
        margin-left:     auto;
        margin-right:    auto;
    }
</style>

<script>

import { apiClient }      from '@/api';
import { ITEM_TYPE }      from "@/constants";
import ThemeButton        from '../../../../../../../components/button/ThemeButton';
import ThumbnailFileImage from '@/views/transportRide/overview/ride/item/options/overlay/ThumbnailFileImage';

export default {
    name: "Photos",
    components: { ThumbnailFileImage, ThemeButton },

    props: {
        item: { type: Object, required: true },
    },
    data() {
        return {
            uploading: false,
        }
    },
    computed: {
        attachments() {
            if (this.purchaseReceipt) return this.purchaseReceipt.attachments;
            else                      return this.packingSlip.attachments;
        },
        packingSlip() {
            if (! this.item.type || this.item.type === ITEM_TYPE.PACKING_SLIP) return this.item.packingSlip;
            else                                                               return null;
        },
        purchaseReceipt() {
           if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) return this.item.purchaseReceipt;
           else                                                                 return null;
        },
        widthClass() {
            return this.attachments.length === 4
                ? 'uk-width-1-2'
                : 'uk-width-1-3'
            ;
        }
    },
    methods: {
        async addPictures(event) {
            try {
                this.uploading = true;
                const pictures = event.target.files;

                let formData = new FormData();
                for (let idx = 0; idx < pictures.length; idx++) {
                    formData.append('files[]', pictures[idx])
                }

                let response = null;
                if (this.purchaseReceipt) {
                    response = await apiClient.post(
                        `transport/purchase_receipts/${this.purchaseReceipt.id}/addPictures`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }
                    );
                } else {
                    response = await apiClient.post(
                        `transport/packing_slips/${this.packingSlip.id}/addPictures`,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                        }
                    );
                }
                this.$emit('updated', response.data.data)
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.uploading = false;
            }
        }
    }
};
</script>
