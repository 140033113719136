<template>
    <div>
        <div
            class="border-around"
            v-if="loading"
            data-uk-spinner
        />
        <full-width-file-image
            v-else-if="url && fullWidthImage"
            :file="file"
            @close="fullWidthImage = false"
        />
        <img
            v-else-if="url"
            :src="url"
            :alt="file.name"
            @click="fullWidthImage = true"
        />
        <div v-else class="no-image">
            <i class="fas fa-exclamation-circle" />
            <p>{{ $t('error.loading') }}</p>
        </div>
    </div>
</template>

<style scoped lang="scss">

img {
    width:         130px;
    height:        130px;
    object-fit:    contain;
    display:       block;
    margin:        auto;
    border:        1px solid #F3F3F3;
    border-radius: 3px;
    background:    var(--theme-button-icon);
}

.border-around {
    width:           130px;
    height:          130px;
    object-fit:      contain;
    margin:          auto;
    border:          1px solid #9d9d9d;
    border-radius:   3px;
    position:        relative;
    display:         flex;
    justify-content: center;
    background:      #F3F3F3;
    padding:         0;
    box-sizing:      border-box;
}

.no-image {
    width:         130px;
    height:        130px;
    margin:        auto;
    border:        1px solid #9d9d9d;
    border-radius: 3px;
    text-align:    center;

    i {
        margin-top: 12px;
        color:      #FFFFFF;
        font-size:  75px;
    }

    p {
        color:      #FFFFFF;
        margin-top: 10px;
        font-size:  14px;
    }
}

</style>

<script>
import { apiClient } from '@/api';
import FullWidthFileImage from '@/views/transportRide/overview/ride/item/options/overlay/FullWidthFileImage';

export default {
    name: "ThumbnailFileImage",
    components: { FullWidthFileImage },
    props: {
        file: { type: Object, required: true },
    },
    data() {
        return {
            fullWidthImage: false,
            loading:        true,
            url:            null,
        };
    },

    async mounted() {
        try {
            const response = await apiClient.get(this.file.thumbnailUrl.replace('/v3/api', ''), { responseType: 'blob' });
            this.url       = URL.createObjectURL(response.data);
        } catch (e) {
            console.error(e); //eslint-disable-line no-console
            throw e;
        } finally {
            this.loading = false;
        }
    },
};
</script>
