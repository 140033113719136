<template>
    <overlay
        ref="overlay"
        class="overlay"
        :loading="loading"
        @closed="$emit('closed')"
    >
        <div class="content">
            <div class="header">
                <div class="icon-border">
                    <i :class="iconClasses" />
                </div>
                <h3>{{ header }}</h3>
                <div
                    v-if="description !== ''"
                    style="margin-top: 10px"
                >
                    {{ description }}
                </div>
            </div>
            <delivery-location
                v-if="action === 'deliveryLocation'"
                :packing-slip="packingSlip"
            />
            <inform-customer
                v-else-if="action === 'inform'"
                :is-notified="isNotified"
                :item="item"
                @informed="handleUpdatedItemObject($event, true)"
            />
            <invoices
                v-else-if="action === 'invoices'"
                :invoices="packingSlip.invoices"
                :item="item"
                :order="packingSlip.order"
                @updated-invoices="updateInvoiceData($event)"
            />
            <photos
                v-else-if="action === 'photos'"
                :item="item"
                @updated="handleUpdatedItemObject($event)"
            />
            <signature
                v-else-if="action === 'sign'"
                :item="item"
                :ride="ride"
                @loading="loading = $event"
                @packingSlipSigned="updatePackingSlip($event, true)"
                @packingSlipUpdated="updatePackingSlip($event, false)"
                @purchaseReceiptSigned="updatePurchaseReceipt($event, true)"
                @purchaseReceiptUpdated="updatePurchaseReceipt($event, false)"
            />
        </div>
    </overlay>
</template>

<style lang="scss" >
    .content {
        color: #FFFFFF;
        text-align: center;

        .header {
            margin: -50px 0 30px;

            .icon-border {
                @include center();
                border:        #fff 5px solid;
                border-radius: 50%;
                width:         150px;
                height:        150px;
                margin-bottom: 20px;

                i {
                    font-size: 70px;
                    position:  relative;
                    top:       35px;
                }
            }
            h3 {
                margin: 0;
            }
        }
    }
</style>

<script>
import { convertToLocaleDateString, convertToLocaleTimeString } from '@/support/dateFunctions';
import DeliveryLocation                                         from '@/views/transportRide/overview/ride/item/options/overlay/DeliveryLocation';
import InformCustomer                                           from './InformCustomer';
import Invoices                                                 from '@/views/transportRide/overview/ride/item/options/overlay/Invoices';
import Overlay                                                  from '../../../../../../../components/overlay/Overlay';
import Photos                                                   from './Photos';
import Signature                                                from './Signature';
import {
    ITEM_TYPE,
    NOTIFY_METHOD_EMAIL,
    NOTIFY_METHOD_PHONE,
    NOTIFY_METHOD_SMS
} from '@/constants';

export default {
    name:       "OptionOverlay",
    components: { DeliveryLocation, InformCustomer, Invoices, Overlay, Photos, Signature },

    props: {
        action: { type: String, required: true },
        item:   { type: Object, required: true },
        ride:   { type: Object, required: true },
    },
    data() {
        return {
            loading:         false,
            notifiedByEmail: false,
            notifiedByPhone: false,
            notifiedBySms:   false
        }
    },
    mounted()
    {
        if (this.action === 'inform') {
            if (! this.notifications) return;
            for (const notification of this.notifications) {
                if (notification.notificationType === 'driver_on_the_way') {
                    if      (notification.notificationMethod === NOTIFY_METHOD_EMAIL) this.notifiedByEmail = true;
                    else if (notification.notificationMethod === NOTIFY_METHOD_PHONE) this.notifiedByPhone = true;
                    else if (notification.notificationMethod === NOTIFY_METHOD_SMS)   this.notifiedBySms   = true;
                }
            }
        }
    },
    computed: {
        description() {
            if (this.action === 'inform') {
                if (! this.notifications) return '';

                for (const notification of this.notifications) {
                    if (notification.notificationType === 'driver_on_the_way') {
                        const date = convertToLocaleDateString(notification.sentAt);
                        const time = convertToLocaleTimeString(notification.sentAt);

                        if (this.notifiedByEmail && this.notifiedBySms) return this.$t('informed_by_on_at.email_and_sms', { date, time });
                        if (this.notifiedByEmail)                       return this.$t('informed_by_on_at.email', { date, time });
                        if (this.notifiedByPhone)                       return this.$t('informed_by_on_at.phone', { date, time });
                        if (this.notifiedBySms)                         return this.$t('informed_by_on_at.sms', { date, time });
                    }
                }
                return this.$t('inform_departure');
            }
            if (this.action === 'photos') return this.$t('upload_create_pic');
            if (this.action === 'sign')   return this.$t('confirm_delivery');
            return '';
        },
        header() {
            if (this.action === 'deliveryLocation') return this.$t('delivery_location');
            if (this.action === 'inform')           return this.$t('inform');
            if (this.action === 'invoices')         return this.$t('invoices');
            if (this.action === 'photos')           return this.$t('proof_of_delivery');
            if (this.action === 'sign')             return this.$t('sign');
            return '';
        },
        iconClasses() {
            let classes = 'fal fa-';
            if (this.action === 'deliveryLocation') return classes + 'location';
            if (this.action === 'inform')           return classes + 'user';
            if (this.action === 'invoices')         return classes + 'file-invoice-dollar';
            if (this.action === 'photos')           return classes + 'camera';
            if (this.action === 'sign')             return classes + 'clipboard-check';
            return '';
        },
        isNotified()
        {
            return (this.notifiedByEmail || this.notifiedByPhone || this.notifiedBySms);
        },
        notifications() {
            if      (this.packingSlip)     return this.packingSlip.relationNotifications;
            else if (this.purchaseReceipt) return this.purchaseReceipt.relationNotifications;
            else                           return [];
        },
        packingSlip() {
            if (this.item.type && this.item.type !== ITEM_TYPE.PACKING_SLIP) return null;
            else                                                             return this.item.packingSlip;
        },
        purchaseReceipt() {
            if (! this.item.type || this.item.type !== ITEM_TYPE.PURCHASE_RECEIPT) return null;
            else                                                                   return this.item.purchaseReceipt;
        }
    },

    methods: {
        handleUpdatedItemObject(event, close = false) {
            if (this.purchaseReceipt) this.updatePurchaseReceipt(event, close);
            else                      this.updatePackingSlip(event, close);
        },
        show() {
            this.$refs.overlay.show();
        },
        updatePackingSlip(packingSlip, close = false) {
            this.$set(this.item, 'packingSlip', packingSlip);

            this.$emit('updated-packing-slip', packingSlip);

            if (close) this.$refs.overlay.hide();
        },
        updatePurchaseReceipt(receipt, close = false) {
            this.$set(this.item, 'purchaseReceipt', receipt);

            this.$emit('purchaseReceiptUpdated', receipt);

            if (close) this.$refs.overlay.hide();
        },
        updateInvoiceData(invoices) {
            let packingSlip      = this.item.packingSlip;
            packingSlip.invoices = invoices;

            this.updatePackingSlip(packingSlip);
        }
    },
};
</script>
