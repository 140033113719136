/**
 * @param  {Date} date
 * @return {string}
 */
export function getBackendDate(date) {
    const year = date.getFullYear(), month = date.getMonth() + 1, day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
}

/**
 * returns the current date as a number, formatted as YYYYMMDD
 * @param  {Date} date
 * @return {Number}
 */
export function getDateNumber(date) {
    return Number(getBackendDate(date).replaceAll('-', ''));
}

/**
 * Returns the given dateTimeString to a local dateTimeString including seconds
 *
 * @param  {string|null} dateString
 * @return {string}
 */
export function convertToLocaleDateTimeString(dateString) {
    if (dateString === null) return '';

    return new Date(dateString).toLocaleString('nl-NL');
}

/**
 * Returns the given date(Time)String to a local dateString
 *
 * @param  {string|null} dateString
 * @return {string}
 */
export function convertToLocaleDateString(dateString) {
    if (dateString === null) return '';

    return new Date(dateString).toLocaleString('nl-NL').split(' ')[0];
}

/**
 * Returns the given dateTimeString to a local timeString
 *
 * @param  {string|null} dateString
 * @return {string}
 */
export function convertToLocaleTimeString(dateString, includeSeconds = false) {
    if (dateString === null) return '';

    const timeString = new Date(dateString).toLocaleString('nl-NL').split(' ')[1];

    return includeSeconds
        ? timeString
        : timeString.slice(0, -3)
    ;
}
