<template>
    <div class="signature">
        <div class="top">
            <div class="border-around item-blue-to-dark">
                <ol>
                    <template v-if="packingSlip">
                        <li>
                            <div class="left">{{ $t('customer') }}</div>
                            <div class="right">{{ packingSlip.customer.id }}</div>
                        </li>
                        <li>
                            <div class="left">{{ $t('order') }}</div>
                            <div class="right">{{ packingSlip.order }}</div>
                        </li>
                        <li>
                            <div class="left">{{ $t('packing_slip') }}</div>
                            <div class="right">{{ packingSlip.id }}</div>
                        </li>
                        <li>
                            <div class="left">{{ $t('date') }}</div>
                            <div class="right">{{ deliveryAt }}</div>
                        </li>
                    </template>
                    <template v-else>
                        <li>
                            <div class="left">{{ $t('supplier') }}</div>
                            <div class="right">{{ purchaseReceipt.supplier.id }}</div>
                        </li>
                        <li>
                            <div class="left">{{ $t('purchase_order') }}</div>
                            <div class="right" v-html="purchaseOrdersHtmlString"></div>
                        </li>
                        <li>
                            <div class="left">{{ $t('purchase_receipt') }}</div>
                            <div class="right">{{ purchaseReceipt.id }}</div>
                        </li>
                        <li>
                            <div class="left">{{ $t('date') }}</div>
                            <div class="right">{{ deliveryAt }}</div>
                        </li>
                    </template>
                </ol>
<!--                TODO In the current use this comment should be for internal communication only, keep the code (but commented out) to see if customers are gonna complain-->
<!--                <div v-if="packingSlip && packingSlip.generalPackingComment !== ''" class="stacked">-->
<!--                    <div class="left">{{ $t('general_packing_comment') }}</div>-->
<!--                    <div class="general-packing-comment">{{ packingSlip.generalPackingComment }}</div>-->
<!--                </div>-->
            </div>
            <div class="details">
                <p>
                    <strong>{{ address.attentionOf }}</strong>
                </p>
                <p>{{ address.address }}</p>
                <p>{{ postcodePlaceString }}</p>
            </div>
        </div>
        <template v-if="packingSlip">
            <div class="data" v-for="line in packingSlip.lines" :key="line.id">
                <div class="amount">{{ line.quantity.value | quantity }}</div>
                <div class="unit">{{ line.quantity.unit }}</div>
                <div class="desc">
                    <div><strong>{{ line.description }}</strong></div>
                    <div>{{ getLinePackagingString(line) }}</div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="data" v-for="line in purchaseReceipt.lines" :key="line.id">
                <div class="amount">{{ line.quantity | quantity }}</div>
                <div class="unit">{{ line.unitText }}</div>
                <div class="desc">
                    <div><strong>{{ line.description }}</strong></div>
                </div>
            </div>
        </template>
        <div class="sign">
            <!--Keep signature canvas at this size!-->
            <vue-signature
                ref="canvas"
                h="200px"
                w="550px"
                :disabled="signing"
                style="width: 100%"
            />
            <input
                v-model="signedBy"
                class="tf-input"
                type="text"
                :disabled="signing"
                :placeholder="$t('signer_name')"
            >
            <div class="contextual-comment">
                <textarea
                    v-model="contextualCommentText"
                    class="comment-textarea"
                    :placeholder="packingSlip ? $t('delivery_comment') : $t('')"
                />
                <div class="comment-action-buttons">
                    <button
                        class="store-comment"
                        :disabled="disableStoringComment"
                        @click="contextualComment ? updateContextualComment() : storeContextualComment()"
                    >
                        <i class="fal fa-save" />
                    </button>
                    <button
                        class="delete-comment"
                        :disabled="disableDeleteComment"
                        @click="deleteContextualComment"
                    >
                        <i class="fal fa-trash" />
                    </button>
                </div>
            </div>
            <template v-if="! signing && ! isSigned">
                <theme-button
                    v-if="! signing"
                    icon="check"
                    :description="$t('sign')"
                    :disabled="signedBy === ''"
                    @click="storeSignature"
                    class="button"
                />
            </template>
        </div>
        <template v-if="deletingComment || signing || storingComment">
            <div class="loading-spinner" :style="`top: ${scrollTop()}`">
                <div data-uk-spinner="ratio: 2" />
                <div v-if="deletingComment">
                    {{ $t('deleting_comment') }}
                </div>
                <div v-else-if="signing">
                    {{ $t('storing_signature') }}
                </div>
                <div v-if="storingComment">
                    {{ $t('storing_comment') }}
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.signature {
    .top {
        display:         flex;
        gap:             20px;
        align-items:     center;
        justify-content: center;
        font-size:       0.9rem;
        color:           #fff;
        margin-bottom:   50px;

        .border-around ol li,
        .stacked,
        .details p {
            text-align: left;
        }
        .border-around {
            border:        1px solid #fff;
            border-radius: 5px;
            padding:       15px;

            ol {
                margin: 0;

                li {
                    display: flex;
                    gap:     10px;

                    .left {
                        width:       100px;
                        font-weight: bold;
                    }
                    .right {
                        width: 100px;
                    }
                }
            }
            .stacked {
                .left {
                    font-weight: bold;
                }
                .general-packing-comment {
                    max-width:  200px;
                    word-break: normal;
                }
            }
        }
        .details {
            p {
                margin: 5px 0;
            }
        }
    }
    .data {
        display:         flex;
        gap:             30px;
        color:           #fff;
        font-size:       0.9rem;
        margin:          20px auto;
        justify-content: center;

        .amount { width: 50px; }
        .unit   { width: 30px; }
        .desc   { flex:  1; }

        .amount,
        .unit {
            margin:      auto 0;
            text-align:  right;
            align-items: center;
        }
        .desc {
            text-align:  left;
            overflow:    hidden;
            white-space: nowrap;

            div {
                overflow:      hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .sign {
        input {
            margin-top: 10px;
        }
        .button {
            position:        relative;
            justify-content: center;
            margin:          30px auto;
            display:         flex;

            &:disabled {
                background: #ccc;
            }
        }
        .contextual-comment {
            display:       flex;
            margin-top:    10px;
            border-radius: 5px;
            overflow:      hidden;

            .comment-textarea {
                flex:          1;
                width:         100%;
                height:        200px;
                resize:        none;
                border-radius: 0;
                border:        none;
                padding:       10px;
                box-sizing:    border-box;
                font-family:   sans-serif;
            }
            .comment-action-buttons {
                width:          75px;
                display:        flex;
                flex-direction: column;
            }
            .store-comment {
                border:     none;
                font-size:  30px;
                background: var(--theme-button-icon);
                color:      #fff;
                flex:       1;
            }
            .delete-comment {
                border:     none;
                font-size:  30px;
                background: #d4473b;
                color:      #ffffff;
                height:     33%
            }
        }
    }
    .loading-spinner {
        /* TODO: Remove these importants. They are set in a global styles.scss (WITH ANNOYING IMPORTANTS!!!) */
        position:        fixed !important;
        margin:          0 !important;
        justify-content: center !important;
        display:         flex !important;

        flex-direction: column;
        align-items:    center;
        gap:            30px;
        top:            0;
        left:           0;
        height:         100%;
        width:          100%;
        background:     rgba(0,0,0,0.75);
        z-index:        11 // Must be higher than 10 (close button)
    }
}
</style>

<style>
.uk-modal-dialog {
    height: 100vh;
}
</style>

<script>
import { apiClient }                 from '@/api';
import { convertToLocaleDateString } from "@/support/dateFunctions";
import ThemeButton                   from '../../../../../../../components/button/ThemeButton';
import VueSignature                  from 'vue-signature'
import { CONTEXT, ITEM_TYPE }        from '@/constants';

/**
 * @param {String} b64Data
 * @param {String} contentType
 * @returns {Blob}
 */
function b64toBlob(b64Data, contentType) {
    const byteCharacters = atob(b64Data);
    const byteArrays     = [];
    const SLICE_SIZE     = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += SLICE_SIZE) {
        const slice = byteCharacters.slice(offset, offset + SLICE_SIZE);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
}

export default {
    name: "Signature",
    components: { ThemeButton, VueSignature },
    props: {
        item: { type: Object, required: true },
        ride: { type: Object, required: true }
    },
    data() {
        return {
            deletingComment:       '',
            contextualCommentText: '',
            signedBy:              '',
            signing:               false,
            storingComment:        false,
        };
    },
    created() {
        if (this.contextualComment) this.contextualCommentText = this.contextualComment.comment;
    },
    computed: {
        address() {
            if (this.packingSlip) return this.packingSlip.deliveryAddress;
            else                  return this.purchaseReceipt.pickUpAddress;
        },
        deliveryAt() {
            if (this.packingSlip) return this.packingSlip.deliveryAt;
            else                  return convertToLocaleDateString(this.purchaseReceipt.deliveryAt);
        },
        contextualComment() {
            let comments = [];
            if (this.packingSlip) {
                comments = this.packingSlip.contextualComments.filter((comment) => comment.context === CONTEXT.DELIVERY);
            } else {
                comments = this.purchaseReceipt.contextualComments.filter((comment) => comment.context === CONTEXT.PICK_UP);
            }
            return comments.length > 0
                ? comments[0]
                : null
            ;
        },
        disableActions() {
            return this.deletingComment || this.storingComment
        },
        disableDeleteComment() {
            return ! this.contextualComment || this.disableActions;
        },
        disableStoringComment() {
            return this.contextualCommentText === '' || this.disableActions;
        },
        isSigned() {
            if (this.packingSlip) return this.packingSlip.isSigned;
            else                  return this.purchaseReceipt.isSigned;
        },
        packingSlip() {
            if (! this.item.type || this.item.type === ITEM_TYPE.PACKING_SLIP) return this.item.packingSlip;
            else                                                               return null;
        },
        postcodePlaceString() {
            let string = this.address.postcode + ' ' + this.address.city;

            return string.trim();
        },
        purchaseOrdersHtmlString() {
            if (! this.purchaseReceipt) return '';
            return this.item.purchaseOrder.join(',<br>')
        },
        purchaseReceipt() {
            if (this.item.type && this.item.type === ITEM_TYPE.PURCHASE_RECEIPT) return this.item.purchaseReceipt;
            else                                                                 return null;
        }
    },
    methods: {
        getLinePackagingString(line) {
            if (! line.quantity.packaging) return '';

            let packaging       = [];
            const packagingData = line.quantity.packaging;
            if (Number(packagingData.packages) !== 0) {
                packaging.push(packagingData.packages + ' ' + this.$t('packaging.package', { count: Math.abs(packagingData.packages) }));
            }
            if (Number(packagingData.layers) !== 0) {
                packaging.push(packagingData.layers + ' ' + this.$t('packaging.layer', { count: Math.abs(packagingData.layers) }));
            }
            if (Number(packagingData.pieces) !== 0) {
                packaging.push(packagingData.pieces + ' ' + this.$t('packaging.piece', { count: Math.abs(packagingData.pieces) }));
            }
            return packaging.join(' - ');
        },
        async storeContextualComment() {
            try {
                this.$emit('loading', true);
                this.storingComment = true;
                if (this.packingSlip) {
                    const response = await apiClient.post(
                        `transport/packing_slips/${this.packingSlip.id}/deliveryComments`,
                        { comment: this.contextualCommentText.trim() }
                    );
                    this.$emit('packingSlipUpdated', response.data.data);

                } else {
                    const response = await apiClient.post(
                        `transport/purchase_receipts/${this.purchaseReceipt.id}/pickUpComments`,
                        { comment: this.contextualCommentText.trim() }
                    );
                    this.$emit('purchaseReceiptUpdated', response.data.data);
                }
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.$emit('loading', false);
                this.storingComment      = false;
                this.contextualCommentText = this.contextualComment
                    ? this.contextualComment.comment
                    : ''
                ;
            }
        },
        async updateContextualComment() {
            try {
                this.$emit('loading', true);
                this.storingComment = true;
                if (this.packingSlip) {
                    const response = await apiClient.put(
                        `transport/packing_slips/${this.packingSlip.id}/deliveryComments/${this.contextualComment.id}`,
                        { comment: this.contextualCommentText.trim() }
                    );
                    this.$emit('packingSlipUpdated', response.data.data);

                } else {
                    const response = await apiClient.put(
                        `transport/purchase_receipts/${this.purchaseReceipt.id}/pickUpComments/${this.contextualComment.id}`,
                        { comment: this.contextualCommentText.trim() }
                    );
                    this.$emit('purchaseReceiptUpdated', response.data.data);
                }
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.$emit('loading', false);
                this.storingComment        = false;
                this.contextualCommentText = this.contextualComment
                    ? this.contextualComment.comment
                    : ''
                ;
            }
        },
        async deleteContextualComment() {
            try {
                this.$emit('loading', true);
                this.deletingComment = true;
                if (this.packingSlip) {
                    const response = await apiClient.delete(`transport/packing_slips/${this.packingSlip.id}/deliveryComments/${this.contextualComment.id}`);
                    this.$emit('packingSlipUpdated', response.data.data);

                } else {
                    const response = await apiClient.delete(`transport/purchase_receipts/${this.purchaseReceipt.id}/pickUpComments/${this.contextualComment.id}`);
                    this.$emit('purchaseReceiptUpdated', response.data.data);
                }
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.$emit('loading', false);
                this.deletingComment       = false;
                this.contextualCommentText = this.contextualComment
                    ? this.contextualComment.comment
                    : ''
                ;
            }
        },
        async storeSignature() {
            if      (this.contextualCommentText !== '' && ! this.contextualComment)                                      await this.storeContextualComment();
            else if (this.contextualCommentText !== '' && this.contextualCommentText !== this.contextualComment.comment) await this.updateContextualComment();

            try {
                this.signing = true;
                this.$emit('loading', true);

                const HEADER = 'data:image/png;base64,';

                let data = this.$refs.canvas.save();
                if (data.substr(0, HEADER.length) !== HEADER) {
                    console.error('Invalid signature file type.');  //eslint-disable-line no-console
                    return;
                }
                const blob = b64toBlob(data.substr(HEADER.length), 'image/png');

                const formData = new FormData();
                formData.append('signature', blob, 'signature.png');
                formData.append('signedBy', this.signedBy);

                if (this.packingSlip) {
                    const response = await apiClient.post(`transport/packing_slips/${this.packingSlip.id}/sign`, formData);
                    this.$emit('packingSlipSigned', response.data.data);
                } else {
                    const response = await apiClient.post(`transport/purchase_receipts/${this.purchaseReceipt.id}/sign`, formData);
                    this.$emit('purchaseReceiptSigned', response.data.data);
                }
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
            } finally {
                this.signing = false;
                this.$emit('loading', false);
            }
        },
        scrollTop() {
            // Can not be a computed property because it won't update (because DOM elements are not reactive)
            let overlay = document.querySelector(".overlay .uk-modal-dialog");
            if (overlay) {
                return `${overlay.scrollTop}px`;
            }
            return 0;
        }
    }
};
</script>
